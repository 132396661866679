import {isEmpty} from '@ivosabev/helpers/isEmpty';
import {isObjectLike} from '@ivosabev/helpers/isObjectLike';
import {isRouteErrorResponse, Links, Meta, useRouteError} from '@remix-run/react';
import {captureRemixErrorBoundaryError} from '@sentry/remix';

// TODO: Improve error handling
type ErrorBoundaryProps = {
  errors?: any;
  isRoot?: boolean;
};

export function ErrorBoundary(props: ErrorBoundaryProps) {
  const {isRoot = true} = props;

  const routeError = useRouteError();
  const propErrors = props.errors;

  let errors: any[] = [];

  if (Array.isArray(propErrors)) {
    errors = propErrors;
  } else if (isRouteErrorResponse(routeError)) {
    errors.push({
      info: routeError.statusText || routeError.data,
      message: routeError.status,
    });
  } else if (routeError instanceof Error) {
    errors.push(routeError);
  } else if (Array.isArray(routeError)) {
    errors = routeError;
  } else {
    errors = [{
      code: 'Unknown Error',
      message: isObjectLike(routeError) && 'message' in routeError ? routeError.message : 'Unknown Error',
    }];
  }

  captureRemixErrorBoundaryError(errors);
  console.error(errors);

  const content = (
    <div className="flex flex-col gap-y-6 p-6 text-red-500">
      <h2 className="pl-2 text-3xl font-medium leading-none tracking-tight">
        {errors.length} Errors
      </h2>
      <div className="rounded-lg border border-red-500 bg-white font-mono">
        <div>
          <div className="flex flex-col divide-y divide-red-500">
            {errors.map((e) => (
              <div className="flex flex-col justify-start gap-x-3 gap-y-1 px-6 py-3" key={e.message}>
                <h2 className="text-lg font-bold">{e.message}</h2>
                <div className="flex flex-row gap-x-3 text-sm">
                  {!isEmpty(e['code']) && (
                    <div>
                      <span className="font-bold">Code: </span>
                      {e.code}
                    </div>
                  )}
                  {!isEmpty(e['path']) && (
                    <div>
                      <span className="font-bold">Path: </span>
                      {e.path.join('.')}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
  console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@', `${errors.length} Errors`);

  return isRoot ? (
    <html className="h-full" lang="en">
      <head>
        <title>{errors.length} Errors</title>
        <Meta />
        <Links />
      </head>
      <body className="h-full bg-slate-100">
        {content}
      </body>
    </html>
  ) : content;
}
